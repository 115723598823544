import './src/styles/global.scss';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeaSgcjAAAAAFKGbRifTL6-HJACxoC-evggXo8f">
      {element}
    </GoogleReCaptchaProvider>
  );
};
